import { useState, useEffect } from "react";

import { generateClient } from "aws-amplify/api";

import {
  queryStopTimesByTripId,
  getBatchTrips,
  getBatchRoutes,
} from "../../graphql/queries";

import Box from "@mui/material/Box";

import {
  diffSec,
  delayMin,
  delaySec,
  getTimeString,
  initBaseTimestamp,
  spentSec,
} from "../Common/CommonFunc";

import { CardList } from "./CardList";
import { DelayAlert } from "./DelayAlert";
import { FilteredCardList } from "./FilteredCardList";
// import { DelayChart } from "./DelayChart";
import { HourlyAverage } from "./HourlyAverage";

export function FilteredWithDelayedStopTimes(props) {
  const client = generateClient();
  const stopTimes = props.stopTimes;
  const idx = props.idx;
  const [uncertainStopTimes, setUncertainStopTimes] = useState();
  const [tripMap, setTripMap] = useState();
  const [routeMap, setRouteMap] = useState();

  async function fetchData(stopTimes) {
    const timestamp0 = initBaseTimestamp();
    const now = Math.floor(Date.now() / 1000);
    const uStArr = [];
    for (const stopTime of stopTimes) {
      const tripId = stopTime.trip_id;
      const seq = stopTime.stop_sequence;
      const sts = await client.graphql({
        query: queryStopTimesByTripId,
        variables: { trip_id: tripId },
      });
      const { items: datas /* nextToken */ } = sts.data.queryStopTimesByTripId;
      let delay_sec = null;
      let passed = null;
      for (const data of datas) {
        // 該当バス停より前の到着時刻があるか（バスロケがあるか）
        if (data.stop_sequence < seq && data.timestamp) {
          delay_sec = delaySec(data.timestamp, data.departure_time);
          stopTime.timestamp =
            timestamp0 + spentSec(stopTime.departure_time) + delay_sec;
        }
        // 当該バス停以降で到着確認がある
        if (data.stop_sequence > seq && data.timestamp) {
          passed = now - data.timestamp;
          break;
        }
      }
      // 通過確認なし
      if (passed === null /*|| passed < 0 */) {
        uStArr.push(stopTime);
      }
    }

    if (uStArr.length === 0) return;
    uStArr.reverse();
    setUncertainStopTimes(uStArr);

    // trips
    const tripIdSet = new Set();
    for (const stopTime of uStArr) {
      tripIdSet.add(stopTime.trip_id);
    }
    const tripIds = Array.from(tripIdSet);
    const t = await client.graphql({
      query: getBatchTrips,
      variables: { ids: tripIds },
    });
    const tMap = new Map();
    for (const tData of t.data.getBatchTrips) {
      tMap.set(tData.trip_id, tData);
    }
    setTripMap(tMap);
    // routes
    const rSet = new Set();
    for (const key of tMap.keys()) {
      rSet.add(tMap.get(key).route_id);
    }
    const r = await client.graphql({
      query: getBatchRoutes,
      variables: { ids: Array.from(rSet) },
    });
    const routes = r.data.getBatchRoutes;
    const rMap = new Map();
    for (const route of routes) {
      rMap.set(route.route_id, route);
    }
    setRouteMap(rMap);
  }

  // delay check
  let n = idx - 1;
  let m = 0;
  const LOOP_MAX = 20;
  const DIFF_SEC_MAX = 3600; // 1 hour
  const nowStr = getTimeString();
  const delayMins = [];
  const tmpStopTimes = [];
  while (n >= 0 && m < LOOP_MAX) {
    if (diffSec(nowStr, stopTimes.at(n).departure_time) > DIFF_SEC_MAX) break;
    if (stopTimes.at(n).timestamp === null) {
      // タイムスタンプがない、到着確認がとれない便を確認するリストに追加
      tmpStopTimes.push(stopTimes.at(n));
    } else {
      let delay_min = delayMin(
        stopTimes.at(n).timestamp,
        stopTimes.at(n).departure_time
      );
      if (delay_min < 0) delay_min = 0;
      delayMins.push(delay_min);
    }
    n--;
    m++;
  }
  const NUM = 5;
  const len = stopTimes.length;
  const end = idx + NUM > len ? len : idx + NUM;
  const filtered = stopTimes.slice(idx, end);

  // 表示本数
  const sum = uncertainStopTimes
    ? uncertainStopTimes.length + filtered.length
    : filtered.length;

  useEffect(() => {
    fetchData(tmpStopTimes);
  }, []);

  return (
    <>
      {delayMins.length > 0 && sum > 0 && <DelayAlert delays={delayMins} />}
      <Box
        sx={{
          m: 2,
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill,minmax(320px,1fr))",
          gap: 2,
        }}
      >
        {routeMap && tripMap && (
          <CardList
            stopTimes={uncertainStopTimes}
            tripMap={tripMap}
            routeMap={routeMap}
            uncertain={true}
          />
        )}
        <FilteredCardList stopTimes={filtered} />
      </Box>
      {sum === 0 && (
        <>
          <Box sx={{ m: 2 }}>本日の運行は終了しました</Box>
          <HourlyAverage stopTimes={stopTimes} />
        </>
      )}
    </>
  );
}
