import { record } from "aws-amplify/analytics";

import { liteClient as algoliasearch } from "algoliasearch/lite";
import { InstantSearch } from "react-instantsearch";
import { history } from "instantsearch.js/es/lib/routers";

import Box from "@mui/material/Box";

import { CommonFooter } from "../Common/CommonFooter";
import { CustomSearchBox } from "../Home/CustomSearchBox";
import { CustomHits } from "../Home/CustomHits";
import { CustomPagination } from "./CustomPagination";

const searchClient = algoliasearch(
  "4QC6DKEQDN",
  "266e2d468394f84445aeba796968f816"
);

const routing = {
  router: history({ cleanUrlOnDispose: false }),
};

export default function Search() {
  const title = "バス停検索 - t2.mame2.link";
  document.title = title;
  // description
  document
    .querySelector('meta[name="description"]')
    .setAttribute("content", title);

  // aws-amplify/analitycs
  record({
    name: "searchVisit",
  });

  return (
    <>
      <main>
        <Box sx={{ m: 2 }}>
          <InstantSearch
            searchClient={searchClient}
            future={{
              preserveSharedStateOnUnmount: false,
            }}
            indexName="stops-index"
            routing={routing}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <CustomSearchBox />
            </Box>
            <CustomHits />
            <CustomPagination />
          </InstantSearch>
        </Box>
      </main>
      <Box sx={{ my: 2 }}>
        <CommonFooter></CommonFooter>
      </Box>
    </>
  );
}
