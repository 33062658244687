import React, { useState, useEffect } from "react";

import { useParams, useLocation } from "react-router-dom";

import { generateClient } from "aws-amplify/api";
import { record } from "aws-amplify/analytics";

import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

import {
  queryStopTimesByTripId,
  getBatchStops,
  getTrips,
  getRoutes,
} from "../../graphql/queries";

import { HideBackHeader } from "../Common/HideBackHeader";
import { CommonFooter } from "../Common/CommonFooter";
import { ExtraBus } from "../Common/ExtraBus";
import TripTimeline from "./TripTimeline";
import { toHalfWidth, setMetaRobotsNoindex } from "../Common/CommonFunc";
import { VehicleNumber } from "../Common/VehicleNumber";
// import { TripNavi } from "./TripNavi";

export default function Trip() {
  const { tripId } = useParams();
  const [stopTimes, setStopTimes] = useState();
  const [stops, setStops] = useState();
  const [trip, setTrip] = useState();
  const [route, setRoute] = useState();
  const [errorStatus, setErrorStatus] = useState(false);
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const seq = query.get("seq");
  let stop = null;

  // noindex
  setMetaRobotsNoindex();

  function getStopById(stopId) {
    if (!stops) return null;
    for (const s of stops) {
      if (s.stop_id === stopId) {
        stop = s;
      }
    }
  }

  async function fetchStopTimes() {
    const client = generateClient();

    // trip
    const t = await client.graphql({
      query: getTrips,
      variables: { trip_id: tripId },
    });
    if (!t.data.getTrips) {
      setErrorStatus(true);
      return; // 便がない
    }
    setTrip(t.data.getTrips);

    // stop_times stops
    const sts = await client.graphql({
      query: queryStopTimesByTripId,
      variables: { trip_id: tripId },
    });
    const { items: datas /*nextToken*/ } = sts.data.queryStopTimesByTripId;
    const ids = new Set();
    for (const data of datas) {
      ids.add(data.stop_id);
    }
    setStopTimes(datas);
    const s = await client.graphql({
      query: getBatchStops,
      variables: { ids: Array.from(ids) },
    });
    setStops(s.data.getBatchStops);

    // route
    const r = await client.graphql({
      query: getRoutes,
      variables: { route_id: t.data.getTrips.route_id },
    });
    setRoute(r.data.getRoutes);
  }

  // route_short_name, headsign
  let headsign = "";
  if (stopTimes) {
    for (const stopTime of stopTimes) {
      if (stopTime.stop_sequence === Number(seq)) {
        headsign = stopTime.stop_headsign;
        getStopById(stopTime.stop_id);
        break;
      }
    }
  }
  if (!headsign || headsign.lengh < 1) headsign = trip?.trip_headsign;
  let title = "";
  if (route?.route_short_name) {
    title = route.route_short_name + " " + headsign;
  }

  let vehicle_num = null;
  let vehicleUrl = null;
  if (route && trip && trip.vehicle_id) {
    vehicleUrl =
      "/vehicle/" + trip.vehicle_id + "?trip_id=" + tripId + "&seq=" + seq;
    if (stop) {
      vehicleUrl += "&stop_id=" + stop.stop_id;
    }
    const n = route.agency_id.length + 1;
    vehicle_num = trip.vehicle_id.slice(n);
  }

  if (errorStatus) {
    title = "本日は該当便がありません - 便ID: " + tripId + " " + seq;
    document.title = title;
    // description
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", title);
  }

  // aws-amplify/analitycs
  record({
    name: "tripVisit",
    attributes: { trip_id: tripId },
  });

  useEffect(() => {
    fetchStopTimes();
  }, []);

  return (
    <>
      {stop && <HideBackHeader stop={stop}></HideBackHeader>}
      <main>
        {stopTimes && stops && trip && route && (
          <Box
            component="h1"
            sx={{
              display: "flex",
              alignItems: "center",
              p: 1,
              m: 2,
              borderRadius: 1,
              bgcolor: "grey.900",
              maxWidth: 470,
            }}
          >
            {route?.route_short_name && (
              <Box
                sx={{
                  color: "secondary.main",
                  border: 2,
                  borderRadius: 1,
                  p: 1,
                  whiteSpace: "nowrap",
                  typography: "h6",
                }}
              >
                {toHalfWidth(route?.route_short_name)}
              </Box>
            )}
            <Box
              sx={{
                flexGrow: 1,
                textAlign: "center",
                typography: "h6",
                color: "common.white",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              {headsign}
            </Box>
          </Box>
        )}
        {trip && trip.is_added && (
          <Box sx={{ ml: 1, display: "inline" }}>
            <ExtraBus></ExtraBus>
          </Box>
        )}
        {vehicle_num && (
          <Box sx={{ m: 2, display: "inline" }}>
            <VehicleNumber vehicle_number={vehicle_num}></VehicleNumber>
          </Box>
        )}
        {vehicleUrl && <Link href={vehicleUrl}>車両情報</Link>}
        {stopTimes && stops && trip && route && (
          <TripTimeline
            stop_times={stopTimes}
            stops={stops}
            trip={trip}
            route={route}
          ></TripTimeline>
        )}
        {errorStatus && (
          <Typography sx={{ m: 2 }}>
            本日は該当便がありません。便は平日や土曜日、祝日などの区分で種類が異なります。またダイヤ改正で名前が変更になる場合もあります🫡
            <br />
            <Link href="/">ホームへ</Link>
          </Typography>
        )}
        {/* {stopTimes && stops && trip && route && (
          <TripNavi trip={trip} seq={seq} />
        )} */}
      </main>
      <Box sx={{ my: 2 }}>
        <CommonFooter></CommonFooter>
      </Box>
    </>
  );
}
